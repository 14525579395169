import { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import { MdClose } from 'react-icons/md';

import useStyles from './Notice.styles';

const Notice = ({ description, fixed, title, type }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  return (
    <Box className={classes.root}>
      <Collapse in={open}>
        <Alert
          action={fixed
            ? null
            : (
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={() => {
                  setOpen(false);
                }}
                size="small"
              >
                <MdClose fontSize="inherit" />
              </IconButton>
          )}
          severity={type}
        >
          {title && <AlertTitle>{title}</AlertTitle>}
          {description}
        </Alert>
      </Collapse>
    </Box>
  );
};

Notice.defaultProps = {
  description: null,
  fixed: false,
  title: null,
  type: 'info',
};

Notice.propTypes = {
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  fixed: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
};

export default Notice;

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    margin: theme.spacing(1, 0),
    textAlign: 'start',
    width: '100%',
  },
}));
